import React from 'react';

const login = React.lazy(() => import('./Authentication/login'));
const Cadastro = React.lazy(() => import('./Authentication/signup'));

const route = [
    // { path: '/novasenha', exact: true, name: 'Nova Senha', component: Cadastro },
    { path: '/auth/signup', exact: true, name: 'Entrar', component: Cadastro },
    { path: '/auth/login', exact: true, name: 'Login', component: login },
];

export default route;
