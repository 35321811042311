import { ApolloClient, HttpLink, InMemoryCache, split } from '@apollo/client';

import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import Axios from 'axios';

export const apiAxios = Axios.create({});
apiAxios.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('accessToken');
  config.headers.Accept = 'application/json';
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

//* start apollo *//
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_GRAPHQL,
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_API_WS,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: async () => {
      // get the authentication token from local storage if it exists
      const token = localStorage.getItem('accessToken');
      return {
        authorization: token ? 'Bearer ' + token : '',
      };
    },
  },
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink
);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('accessToken');
  return {
    headers: {
      ...headers,
      Authorization: token ? 'Bearer ' + token : '',
    },
  };
});

export const apolloClient = new ApolloClient({
  connectToDevtools: true,
  link: authLink.concat(link),
  cache: new InMemoryCache(),
});

//* end apollo *//

export const graphQLErrors = (eObject) => {
  try {
    let _error;
    if (eObject.graphQLErrors !== undefined) {
      eObject.graphQLErrors.forEach((d) => {
        _error = d.extensions.exception;
        if (d.message != null) {
          _error.message = d.message;
        }
      });
    }

    if (_error?.codeName === 'DuplicateKey') {
      const key = Object.keys(_error.keyPattern)[0];
      _error = {
        [key]: {
          message: `O valor ${_error.keyValue[key]} já foi cadastrado.`,
        },
      };
    }

    if (
      (!_error || Object.keys(_error).length === 0) &&
      eObject.networkError?.result != null
    ) {
      _error = {};
      eObject.networkError.result.errors.forEach((d) => {
        const match =
          d.message.match(/\$([\w\d]+)/g)?.[0] ||
          d.message.match(/"([\w\d]+)"/g)?.[1] ||
          '';
        const k = match.replace(/"/g, '').replace('$', '');
        _error[k] = 'Verifique o valor informado';
      });
    }
    if (!_error || Object.keys(_error).length === 0) {
      const usuario = eObject.message?.split(':')[1]?.trim();
      _error = { usuario };
    }
    delete _error['stacktrace'];

    function normalizeError(stacktrace, title) {
      /** @type {Record<string, string>} */
      let retObj = {};
      const addError = (stacktrace, title) => {
        retObj = { ...retObj, ...normalizeError(stacktrace, title) };
      };

      if (stacktrace.hasOwnProperty('errors')) {
        addError(stacktrace.errors, title);
      } else if (stacktrace.hasOwnProperty('message')) {
        console.log(stacktrace.message, title)
        if (typeof stacktrace.message == 'object') {
          addError(stacktrace.message, title);
        } else {
          if (title) {
            retObj[title] = stacktrace.message;
          } else if (stacktrace.path) {
            retObj[stacktrace.path] = stacktrace.message;
          } else if (stacktrace.kind) {
            retObj[stacktrace.kind] = stacktrace.message;
          } else if (!retObj.erro) {
            retObj.erro = stacktrace.message;
          }
        }
      } else {
        Object.keys(stacktrace).forEach((e) => {
          if (stacktrace[e]) {
            let tmpTitle = title || e;
            if (typeof stacktrace[e] == 'object') {
              addError(stacktrace[e], tmpTitle);
            } else {
              retObj[tmpTitle] = stacktrace[e];
            }
          }
        });
      }

      return retObj;
    }

    return normalizeError(_error);
  } catch (e) {
    console.error('Error when trying to parse graphQL error: ', e);
    console.error('Original graphQL error: ', eObject && { ...eObject });
  }
};
