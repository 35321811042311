import NotificationSystem, { Notification } from 'react-notification-system';
import React, {
  ReactChildren,
  createContext,
  memo,
  useCallback,
  useRef,
  useState,
} from 'react';

import { MenusQuery } from '../../services/gql/generated';
import { StateSetter } from '../../utils/typed';

type NotificationId = number | string | Notification;
interface UtilsContextProps {
  addNotification(notification: Notification): Notification | null;
  removeNotification(notification: NotificationId): void;
  clearNotifications(): void;
  editNotification(notification: NotificationId, newNotification: Notification): void;
  menusSistema: MenusQuery;
  setMenusSistema: StateSetter<MenusQuery>;
}

export const UtilsContext = createContext<UtilsContextProps>(
  new Proxy(
    {},
    {
      get() {
        throw new Error('UtilsProvider não encontrado na hierarquia');
      },
    }
  ) as any
);

function UtilsContextProvider(props: { children: ReactChildren }) {
  const [menusSistema, setMenusSistema] = useState<MenusQuery>({ items: [] });
  const notificationSystem = useRef<NotificationSystem.System>(null);

  const addNotification = useCallback(
    (notification: Notification) =>
      notificationSystem.current &&
      notificationSystem.current.addNotification(notification),
    []
  );
  const removeNotification = useCallback(
    (notification: NotificationId) =>
      notificationSystem.current &&
      notificationSystem.current.removeNotification(notification),
    []
  );
  const clearNotifications = useCallback(
    () => notificationSystem.current && notificationSystem.current.clearNotifications(),
    []
  );
  const editNotification = useCallback(
    (notification: NotificationId, newNotification: Notification) =>
      notificationSystem.current &&
      notificationSystem.current.editNotification(notification, newNotification),
    []
  );

  return (
    <UtilsContext.Provider
      value={{
        menusSistema,
        setMenusSistema,
        addNotification,
        removeNotification,
        clearNotifications,
        editNotification,
      }}
    >
      <NotificationSystem ref={notificationSystem} />
      {props.children}
    </UtilsContext.Provider>
  );
}
export const UtilsProvider = memo(UtilsContextProvider);
