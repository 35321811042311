import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import 'dropzone/dist/min/dropzone.min.css';
import 'font-awesome/scss/font-awesome.scss';
import 'open-iconic/font/css/open-iconic.css';
import 'open-iconic/font/css/open-iconic-bootstrap.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-dropzone-component/styles/filepicker.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

import React, { Suspense, useLayoutEffect, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';

import { ApolloProvider } from '@apollo/client';
import Aux from '../hoc/_Aux';
import Loadable from 'react-loadable';
import Loader from './layout/Loader';
import ScrollToTop from './layout/ScrollToTop';
import { SessionProvider } from './contexts/session';
import { UtilsProvider } from './contexts/utils';
import { apolloClient } from '../services';
import routes from '../UnauthenticatesRoutes';

if (process.env.NODE_ENV === 'production') console.debug = () => {};

const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader,
});

const App = () => {
    const menu = useMemo(() => routes.map((route, index) => {
        return route.component ? (
            <Route
                key={index}
                path={route.path}
                exact={route.exact}
                name={route.name}
                render={(props) => <route.component {...props} />}
            />
        ) : null;
    }), []);

    useLayoutEffect(() => {
        const token = localStorage.getItem('accessToken');
        if (!token && !window.location.href.toString().includes('login')) {
            window.location.href = '/auth/login';
        }

        return () => {
            console.warn('bye bye!');
        };
    }, []);

    return (
        <Aux>
            <UtilsProvider>
                <SessionProvider>
                    <ApolloProvider client={apolloClient}>
                        <ScrollToTop>
                            <Suspense fallback={<Loader />}>
                                <Switch>
                                    {menu}
                                    <Route path="/" component={AdminLayout} />
                                </Switch>
                            </Suspense>
                        </ScrollToTop>
                    </ApolloProvider>
                </SessionProvider>
            </UtilsProvider>
        </Aux>
    );
};

export default App;
